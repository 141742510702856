import React from "react";
import { Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DiMongodb, DiGit } from "react-icons/di";
import {
  SiTypescript,
  SiJavascript,
  SiGoland,
  SiRust,
  SiReact,
  SiVuedotjs,
  SiAngular,
  SiSvelte,
  SiMysql,
  SiDocker,
  SiElectron,
  SiTauri,
  SiWebassembly,
  SiGithubactions,
  SiDeno,
  SiBun,
  SiNodedotjs,
  SiCsharp,
} from "react-icons/si";

function getToolTip(name) {
  return <Tooltip id={`tooltip_${name}`}>{name}</Tooltip>;
}
function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <OverlayTrigger placement="top" overlay={getToolTip("NodeJs")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiNodedotjs />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("Deno")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiDeno />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("Bun")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiBun />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("JavaScript")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiJavascript />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("TypeScript")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiTypescript />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("Rust")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiRust />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("Goland")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiGoland />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("Webassembly")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiWebassembly />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("Docker")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiDocker />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("React")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiReact />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("Vue.js")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiVuedotjs />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("Angular")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiAngular />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("Svelte")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiSvelte />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("Electron")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiElectron />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("Tauri")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiTauri />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("MongoDB")}>
        <Col xs={4} md={2} className="tech-icons">
          <DiMongodb />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("C#")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiCsharp />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("MySQL")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiMysql />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("Git")}>
        <Col xs={4} md={2} className="tech-icons">
          <DiGit />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("GitHub Actions")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiGithubactions />
        </Col>
      </OverlayTrigger>
    </Row>
  );
}

export default Techstack;
