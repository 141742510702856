import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next).init({
  fallbackLng: "fr",
  // debug: true,
  resources: {
    en: {
      translation: {
        MyProjects: "Projects",
        ProjectsDesc: "Side projects i made on my free time",
        FeaturedProjects: "Featured Projects",
        FeaturedProjectsDesc: "",
        SchoolProjects: "School projects",
        SchoolProjectsDesc: "Some cool projets i had to make in school",
        movies: " Cinema ( I went to the Festival de cannes 2024 )",
        games: " Video Games",
        adventures: " Comedy",
        coolQuote: "It's not a quote",
        me: "me",
        hiEveryoneIam: "Hi I'am ",
        quentingruber: "Quentin Gruber",
        from: " from ",
        strasbourgFrance: "Strasbourg, France",
        quentinGruberAbout:
          "I develop mainly in JS/TS in the Nodejs ecosystem. I'm passionate about new technologies and try out as many as I can. I like to dabble in everything, and the unknown doesn't scare me.",
        ApartFromCodingWhatILike:
          "Apart from coding I like other cool stuff 🤠 :",
        daysICode: "Open source activity",
        ToolsIUse: "Tools I use",
        WhoAmI: "Who am i ?",
        ProfessionalSkillset: "Professional Skillset",
        DownloadCv: " Download CV",
        "LET ME": "LET ME ",
        INTRODUCE: "INTRODUCE ",
        MYSELF: "MYSELF",
        presentation1:
          "Bonjour! I'm Quentin, a dynamic and enthusiastic young developer hailing from France 🇫🇷. My journey in the tech realm has been a thrilling exploration of new technologies, and I genuinely love what I do. I'm here to bring my energy and expertise to your projects!",
        presentation2: `*Key Highlights*:


         - 🐢 Node.js Aficionado: Since 2019, I've immersed myself in the world of Node.js, delivering robust solutions and staying at the forefront of web development trends.
         - 🐳 Container Maestro: Proficient in Docker, I seamlessly orchestrate containerized applications, ensuring scalability and efficiency in deployment.
         - 🔄 Reversing Magic: Experienced in reversing C/C++ applications, adding a layer of security and optimization to your projects.
         - 🌐 Polyglot Developer: Over the last 2 years, I've delved into the realms of Golang and Rust, expanding my toolkit to create efficient, performant, and secure applications.
         - 💼 TypeScript Expertise: Well-versed in TypeScript, enhancing the robustness and maintainability of my projects.
         - 🖥️ Versatility in Languages: While Node.js, Golang, and Rust are my recent passions, I've also danced with C# to add a touch of versatility to my skill set.
         - ⏰ Work Ethic: I have a strong work ethic and am not afraid to put in the hours. My commitment to your projects is unwavering, and I approach each task as if it were my own "baby."`,
        highlightTitle: `*My Tech Playground*:


        - 🐢 Node.js: Extensive experience in developing scalable and dynamic web applications.
        - 🌐 Web Frameworks: Well-versed in Vue.js, React.js for crafting dynamic and responsive user interfaces.
        - 🚀 Express.js & Nest.js (Backend Frameworks): Proficient in these backend frameworks for crafting scalable and efficient server-side applications.
        - 🚀 Golang & Rust: Proficient in crafting high-performance applications with a focus on efficiency and security.
        - 🖥️ C#: A versatile language in my toolkit, adding depth to my development capabilities.
        - 🐋 Docker Expertise: Orchestrating containerized applications for streamlined deployment and scalability.
        - ⚙️ Reversing Skills: Adding a layer of security and optimization to applications.
        - 🛠️ ORM & Databases: Experienced with Sequelize ORM, and adept at working with databases like MySQL and MongoDB.`,
        highlights: `*Cloud Services and Hosting*:

        
        - ☁️ Cloud Virtuoso: Proficient in AWS and Linode (Akamai Cloud), ensuring your applications are seamlessly hosted and scalable.`,
        "FIND ME ON": "FIND ME ON",
        Home: "Home",
        About: "About",
        Projects: "Projects",
        Resume: "Resume",
      },
    },
    fr: {
      translation: {
        MyProjects: "Projets",
        ProjectsDesc: "Projets personnels réalisés pendant mon temps libre",
        FeaturedProjects: "Projets en vedette",
        FeaturedProjectsDesc: "",
        SchoolProjects: "Projets scolaires",
        SchoolProjectsDesc:
          "Quelques projets sympas que j'ai dû réaliser pendant mes études",
        movies: " Le cinéma (Je suis allé au Festival de Cannes 2024)",
        games: " Les jeux vidéos",
        adventures: " Comédie",
        coolQuote: "Ceci n'est pas une citation",
        me: "moi",
        hiEveryoneIam: "Je suis ",
        quentingruber: "Quentin Gruber",
        from: " et je suis originaire de ",
        strasbourgFrance: " Strasbourg, France",
        quentinGruberAbout:
          "Je développe principalement en JS/TS dans l'écosystème Nodejs. Je suis passionné par les nouvelles technologies et j'essaie autant que possible de les découvrir. J'aime toucher à tout, et l'inconnu ne me fait pas peur.",
        ApartFromCodingWhatILike:
          "En dehors du codage, j'aime d'autres trucs sympas 🤠 :",
        daysICode: "Activité open source",
        ToolsIUse: "Outils que j'utilise",
        WhoAmI: "Qui suis-je ?",
        ProfessionalSkillset: "Compétences professionnelles",
        DownloadCv: "Télécharger le CV",
        "LET ME": "LAISSEZ-MOI",
        INTRODUCE: "ME",
        MYSELF: "PRÉSENTER",
        presentation1:
          "Bonjour !\n Je suis Quentin, un jeune développeur dynamique et enthousiaste originaire de France 🇫🇷 . Mon parcours dans le monde de la technologie a été une exploration passionnante des nouvelles technologies, et j'aime sincèrement ce que je fais. Je suis là pour apporter mon énergie et mon expertise à vos projets !",
        presentation2: `*Points fort*:

          - 🐢 Adepte de Node.js: Depuis 2019, je me suis immergé dans le monde de Node.js, livrant des solutions robustes et restant à la pointe des tendances de développement web.
          - 🐳 Maestro des conteneurs: Maîtrisant Docker, j'orchestre de manière transparente des applications conteneurisées, garantissant l'évolutivité et l'efficacité du déploiement.
          - 🔄 Magie du Reverse Engineering: Expérimenté dans l'inversion des applications C/C++, ajoutant une couche de sécurité et d'optimisation à vos projets.
          - 🌐 Développeur Polyglotte: Au cours des deux dernières années, je me suis plongé dans les domaines de Golang et Rust, élargissant ma boîte à outils pour créer des applications efficaces, performantes et sécurisées.
          - 💼 Expertise en TypeScript: Je maîtrise parfaitement TypeScript, ce qui améliore la robustesse et la maintenabilité de mes projets.
          - 🖥️ Polyvalence en Langages: Bien que Node.js, Golang et Rust soient mes passions récentes, j'ai également dansé avec C# pour ajouter une touche de polyvalence à mon ensemble de compétences.
          - ⏰ Éthique de travail: J'ai une forte éthique de travail et je n'ai pas peur de faire des heures. Mon engagement envers vos projets est inébranlable et j'aborde chaque tâche comme s'il s'agissait de mon propre "bébé".`,
        highlightTitle: `*Mon Terrain de Jeu Technologique*:

          - 🐢 Node.js: Vaste expérience dans le développement d'applications web évolutives et dynamiques.
          - 🌐 Web Frameworks: Bonne connaissance de Vue.js, React.js pour la création d'interfaces utilisateur dynamiques et réactives.
          - 🚀 Express.js & Nest.js (Backend Frameworks): Compétent dans ces frameworks backend pour la création d'applications serveur évolutives et efficaces.
          - 🚀 Golang & Rust: Compétent dans la création d'applications haute performance avec un accent sur l'efficacité et la sécurité.
          - 🖥️ C#: Un langage polyvalent dans ma boîte à outils, ajoutant de la profondeur à mes capacités de développement.
          - 🐋 Expertise Docker: Orchestrer les applications conteneurisées pour un déploiement rationalisé et une meilleure évolutivité.
          - ⚙️ Compétences en Reverse Engineering: Ajout d'une couche de sécurité et d'optimisation aux applications.
          - 🛠️ ORM & Bases de données: Expérimenté avec l'ORM Sequelize, et compétent dans le travail avec des bases de données telles que MySQL et MongoDB.`,
        highlights: `*Services cloud et hébergement*:

          - ☁️ Cloud Virtuoso: Compétent dans AWS et Linode (Akamai Cloud), assurant l'hébergement transparent et évolutif de vos applications.`,
        "FIND ME ON": "TROUVEZ-MOI SUR",
        Home: "Home",
        About: "À propos",
        Projects: "Projets",
        Resume: "CV",
      },
    },
  },
});
