import React from "react";
import { Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  SiNeovim,
  SiGithub,
  SiLinux,
  SiVisualstudiocode,
} from "react-icons/si";

function getToolTip(name) {
  return <Tooltip id={`tooltip_${name}`}>{name}</Tooltip>;
}
function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <OverlayTrigger placement="top" overlay={getToolTip("GitHub")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiGithub />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("Neovim")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiNeovim />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={getToolTip("Linux")}>
        <Col xs={4} md={2} className="tech-icons">
          <SiLinux />
        </Col>
      </OverlayTrigger>
      <OverlayTrigger
        placement="top"
        overlay={getToolTip("Visual Studio Code")}
      >
        <Col xs={4} md={2} className="tech-icons">
          <SiVisualstudiocode />
        </Col>
      </OverlayTrigger>
    </Row>
  );
}

export default Toolstack;
